<script>
import Layout from "@/router/layouts/main";
import { api } from "@/state/services";
import { VclList } from "vue-content-loading";
import Stat from "@/components/widgets/stat";

export default {
  locales: {
    pt: {
      Sponsored: "Indicados",
      "Pending Confirmations": "Confirmações Pendentes",
      Username: "Usuário",
      Name: "Nome",
      Date: "Data",
      "E-mail": "E-mail",
      Cellphone: "Celular",
      Boards: "Tabuleiros",
      Status: "Status",
      Pending: "Pendente",
      Approved: "Aprovado",
      "We apologize, we are unable to retrieve information at this time. Please try again later.":
        "Pedimos desculpas, não podemos recuperar informações no momento. Por favor, tente novamente mais tarde.",
      "No records found.": "Nenhum registro encontrado.",
      Graduation: "Graduação",
    },
    es: {
      Sponsored: "Patrocinados",
      "Pending Confirmations": "Confirmaciones Pendientes",
      Username: "Nombre de Usuario",
      Name: "Nombre",
      Date: "Data",
      "E-mail": "E-mail",
      Cellphone: "Celular",
      Boards: "Tableros",
      Status: "Status",
      Pending: "Pendiente",
      Approved: "Aprobado",
      "We apologize, we are unable to retrieve information at this time. Please try again later.":
        "Nos disculpamos, no podemos recuperar información en este momento. Por favor, inténtelo de nuevo más tarde.",
      "No records found.": "No se encontraron registros.",
      Graduation: "Graduación",
    },
  },
  components: {
    Layout,
    VclList,
    Stat,
  },
  data() {
    return {
      table: {
        body: null,
        loading: true,
        errored: false,
        empty: false,
      },

      statData: null,

      boards: null,

      confirm: {
        modal: false,
        board: null,
        loading: false,
        alert: {
          type: "",
          message: "",
        },
      },
    };
  },
  methods: {
    getSponsored() {
      api
        .get("network/sponsored")
        .then((response) => {
          if (response.data.status == "success") {
            this.table.body = response.data.list;
            this.statData = [
              {
                title: "Sponsored",
                value: response.data.total.toString(),
              },
            ];
          }
        })
        .catch((error) => {
          this.table.errored = error;
        })
        .finally(() => {
          this.table.loading = false;
          if (this.table.body == "" || this.table.body == null) {
            this.table.empty = true;
          }
        });
    },
    getBoards() {
      api.get("boards/sponsored").then((response) => {
        if (response.data.status == "success") {
          this.boards = response.data.list;
        }
      });
    },
    setBoard(board) {
      this.confirm.modal = true;
      this.confirm.board = board;
    },
    confirmBoard(id) {
      this.confirm.loading = true;

      api
        .post("boards/sponsored", {
          board: id,
        })
        .then((response) => {
          if (response.data.status == "success") {
            this.$noty.success(response.data.message)

            this.getSponsored();
            this.getBoards();
          } else {
            this.$noty.error(response.data.message)
          }

          this.confirm.modal = false;
          this.confirm.board = null;
          this.confirm.loading = false;
        });
    },
  },
  mounted() {
    this.getSponsored();
    this.getBoards();
  },
};
</script>

<template>
  <Layout>
    <div class="row">
      <div class="col-12">
        <div class="page-title-box d-flex justify-content-between">
          <h4 class="mb-0 font-size-18">{{ t("Sponsored") }}</h4>
        </div>
      </div>
    </div>

    <div class="row">
      <div v-for="stat of statData" :key="stat.icon" class="col-md-4">
        <Stat :icon="stat.icon" :title="stat.title" :value="stat.value" />
      </div>
    </div>

    <div class="divisor mb-3">
      <span>{{ t("Pending Confirmations") }}</span>
    </div>

    <div class="row">
      <div class="col-lg-12">
        <div
          v-if="boards && boards.length > 0"
          class="bg-soft-warning p-3 mb-3 rounded d-flex align-items-center"
        >
          <i class="lnr lnr-warning" style="font-size: 30px"></i>
          <div class="px-3 flex-fill">
            <h6 class="m-0 font-size-15">
              Aguarde a doação dos seus indicados e depois clique em confirmar.
            </h6>
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <div v-if="boards && boards.length == 0" class="text-center">
              Nenhuma confirmação pendente.
            </div>
            <div
              v-else-if="boards && boards.length > 0"
              class="table-responsive"
            >
              <table class="table table-centered table-nowrap">
                <thead class="thead-light">
                  <tr>
                    <th></th>
                    <th>Tabuleiro</th>
                    <th>Usuário</th>
                    <th>Doação</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(td, index) in boards" :key="index">
                    <td>
                      <img
                        v-if="td.avatar"
                        class="rounded-circle header-profile-user"
                        :src="td.avatar"
                        alt=""
                        style="width: 40px; height: 40px"
                      />
                      <img
                        v-else
                        class="rounded-circle header-profile-user"
                        :src="
                          'https://ui-avatars.com/api/?background=1BB7FE&color=fff&name=' +
                          td.name
                        "
                        alt=""
                        style="width: 40px; height: 40px"
                      />
                    </td>
                    <td>{{ td.id.split("-")[0] }}<br />{{ td.date }}</td>
                    <td class="notranslate">
                      {{ td.username }}<br />
                      {{ td.name }}<br />
                      {{ td.cellphone }}
                      <a
                        target="_blank"
                        :href="
                          'https://api.whatsapp.com/send?phone=' +
                          td.cellphone.replace('+', '').replace('.', '').replace(' ', '').replace('-', '')
                        "
                      >
                        <i
                          class="bx bxl-whatsapp font-size-24 align-middle text-success"
                        ></i>
                      </a>
                    </td>
                    <td>
                      {{ td.donation | currency }}<br />
                      <span
                        class="badge badge-soft-danger font-size-12 text-uppercase"
                        >PENDENTE</span
                      >
                    </td>
                    <td class="text-right">
                      <button
                        class="btn btn-success btn-sm"
                        v-on:click="setBoard(td)"
                      >
                        CONFIRMAR
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div v-else>
              <vcl-list class="col-lg-6"></vcl-list>
            </div>
          </div>
        </div>
      </div>
    </div>

    <b-modal v-model="confirm.modal" :title="t('Confirmar Doação')" centered>
      <b-form
        v-if="confirm.board"
        @submit.prevent="confirmBoard(confirm.board.id)"
        class="text-center"
      >
        <div
          v-if="confirm.alert.message"
          :class="'alert ' + confirm.alert.type"
        >
          {{ confirm.alert.message }}
        </div>
        <div>
          <h5 class="mb-3">
            Deseja confirmar a doação referênte ao tabuleiro
            <strong>{{ confirm.board.id.split("-")[0] }}</strong> de
            <strong>{{ confirm.board.donation | currency }}</strong>
            de <strong class="notranslate">{{ confirm.board.username }}</strong
            >?
          </h5>
          <b-button
            :disabled="confirm.loading == true"
            type="submit"
            variant="default"
          >
            Confirmar
            <b-spinner
              v-if="confirm.loading"
              small
              class="ml-2 align-middle"
              variant="white"
              role="status"
            ></b-spinner>
          </b-button>
        </div>
      </b-form>
      <template v-slot:modal-footer>
        <div></div>
      </template>
    </b-modal>

    <div class="divisor mb-3">
      <span>{{ t("Sponsored") }}</span>
    </div>

    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div v-if="table.errored" class="text-center">
              {{
                t(
                  "We apologize, we are unable to retrieve information at this time. Please try again later."
                )
              }}
            </div>
            <div v-else-if="table.empty" class="text-center">{{ t("No records found.") }}</div>
            <div v-else class="table-responsive">
              <vcl-list v-if="table.loading" class="col-lg-6"></vcl-list>
              <table v-else class="table table-centered table-nowrap">
                <thead class="thead-light">
                  <tr>
                    <th></th>
                    <th>Usuário</th>
                    <th>Contatos</th>
                    <th>Indicados</th>
                    <th>Tabuleiros</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(td, index) in table.body" :key="index">
                    <td>
                      <img
                        v-if="td.avatar"
                        class="rounded-circle header-profile-user"
                        :src="td.avatar"
                        alt=""
                        style="width: 50px; height: 50px"
                      />
                      <img
                        v-else
                        class="rounded-circle header-profile-user"
                        :src="
                          'https://ui-avatars.com/api/?background=1BB7FE&color=fff&name=' +
                          td.name
                        "
                        alt=""
                        style="width: 50px; height: 50px"
                      />
                    </td>
                    <td class="notranslate">
                      {{ td.username }}<br />
                      {{ td.name }}<br />
                    </td>
                    <td>
                      {{ td.email }}<br />
                      {{ td.cellphone }}
                      <a
                        target="_blank"
                        :href="
                          'https://api.whatsapp.com/send?phone=' +
                          td.cellphone.replace('+', '').replace('.', '').replace(' ', '').replace('-', '')
                        "
                      >
                        <i
                          class="bx bxl-whatsapp font-size-24 align-middle text-success"
                        ></i>
                      </a>
                    </td>
                    <td>{{ td.sponsoreds }}</td>
                    <td>
                      <div class="d-flex">
                        <div v-for="(boa, i) in td.boards" :key="i" class="board-position">
                          <img
                            v-b-tooltip.hover
                            :title="boa.level.toUpperCase()"
                            style="width: 45px"
                            :class="[boa.positions == 0 ? 'disabled' : '']"
                            :src="
                              require('@/assets/images/boards/' +
                                boa.id +
                                '-' +
                                boa.level +
                                '.png')
                            "
                          />
                          <sup class="board-total badge badge-light">{{ boa.positions }}</sup>
                        </div>
                      </div>
                    </td>
                    <td class="text-right">
                      <img class="img-responsive" :src="'https://flagcdn.com/24x18/' + td.country.toLowerCase() + '.png'">
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<style scoped>
.divisor {
  position: relative;
  text-align: center;
  text-transform: uppercase;
}
.divisor span {
  display: inline-block;
  padding: 0 5px;
  background: #fff;
  font-size: 10px;
  letter-spacing: 2px;
  color: #888;
}
.divisor:before {
  content: "";
  display: block;
  position: absolute;
  z-index: -1;
  left: 0;
  right: 0;
  top: 52%;
  height: 1px;
  background: #ccc;
}
.board-position {
  position: relative;
}
.board-total {
  position: absolute;
  top: 0px;
  right: 0px;
}
.disabled {
  filter: grayscale(1);
  opacity: 0.5;
}
</style>
